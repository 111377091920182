<template>
  <div class="ManageContacts">


    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      color="black">
    </loading>



    <CRow>
      <CCol sm="6">
        <h1> Contacts du cabinet </h1>
      </CCol>
      <CCol class="text-right align-self-center" sm="6">
        <CButton
          @click="$router.push('add-new-contact')"
          shape="pill"
          color="success">
            <CIcon name="cil-plus"/> Ajouter un nouveau contact
        </CButton>
      </CCol>
    </CRow>


    <CCard class="mt-3">
      <CCardBody>
        <CContainer>
          <CRow>
            <CCol class="text-right">
              <a v-if="!isAllContactsFilter"
                class="eclerk-link"
                href="#" @click="filterAllContacts">
                Voir <strong>tous</strong> les contacts
              </a>
              <a v-if="!isAllOwnContactsFilter"
                class="ml-2 eclerk-link"
                href="#" @click="filterAllOwnContacts">
                Voir uniquement <strong>vos</strong> contacts
              </a>

            </CCol>
          </CRow>

          <CRow class="mt-3">
            <CCol  class="align-self-center">
              <CForm v-on:submit.prevent="searchContactByFirstOrLastName()">
                <CInput
                  type="text" maxlength="250"
                  v-model.trim.lazy="searchContactFirstOrLastName"
                  @input="$v.searchContactFirstOrLastName.$touch()"
                  :state="$v.searchContactFirstOrLastName.$dirty ? !$v.searchContactFirstOrLastName.$error : null"
                  placeholder="Rechercher par prénom ou nom du contact">
                  <template #append>
                    <CButton
                      type="submit"
                      color="outline-dark"
                      :disabled="$v.searchContactFirstOrLastName.$invalid">
                      <CIcon name="cil-magnifying-glass"/>
                    </CButton>
                  </template>
                </CInput>
              </CForm>
            </CCol>
          </CRow>
        </CContainer>
      </CCardBody>
    </CCard>


    <CCard class="mt-3">

      <CCardBody>

        <CRow v-if="userGroupContains(['MANAGER']) && contactsRender.length >0">
          <CCol class="text-right">
            <exportTableDataComponent
              v-bind:data="contactsRender"
              v-bind:fields="contactsFileRenderFields"
              name="Eclerk - Vos contacts"
              label="Exporter vos contacts"
            />
          </CCol>
        </CRow>

        <CDataTable
          class="mt-1 cursor-table"
          :items="contactsRender"
          :fields="contactsRenderFields"
          @row-clicked="goToContactPage"
          :noItemsView='{ noResults: "Aucun résultat", noItems: "Aucun résultat" }'
        >
        <template #telephone="{item}">
          <td v-if="item.telephone">
            +{{returnPhoneDial(item.phone_country_code)}} {{item.telephone}}
          </td>
          <td v-else>
            <em>Non renseigné</em>
          </td>
        </template>
        <template #date_de_naissance="{item}">
          <td>
           <span v-if="item.date_de_naissance"> {{$dayjs(item.date_de_naissance).format('DD/MM/YYYY')}}</span>
           <span v-else> Non renseigné </span>
          </td>
        </template>
        </CDataTable>

        <CContainer>
          <CRow>
            <CCol class="text-center">
              <CButton
                v-if="getAllContactsUrl && isAllContactsFilter"
                @click="getAllContacts(false)"
                class="px-4"
                shape="pill"
                block
                color="outline-dark">
                Voir plus
              </CButton>

              <CButton
                v-if="getAllOwnContactsUrl && isAllOwnContactsFilter"
                @click="getAllOwnContacts(false)"
                class="px-4"
                shape="pill"
                block
                color="outline-dark">
                Voir plus
              </CButton>
            </CCol>
          </CRow>
        </CContainer>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import { apiBaseUrl } from '@/variables/localVariables'

import exportTableDataComponent from '@/components/exportTableDataComponent'

import { APIUserConnected } from '@/api/APIUserConnected'
import { validationMixin } from 'vuelidate'
import userMixins from '@/mixins/userMixins'
import renderMixins from '@/mixins/renderMixins'

import { required, minLength, maxLength } from 'vuelidate/lib/validators'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

const apiUserConnected = new APIUserConnected()

export default {
  name: 'ManageContacts',
  components: {
    exportTableDataComponent,
    Loading
  },
  mixins: [
    validationMixin,
    userMixins,
    renderMixins
  ],
  data: function () {
    return {

      isLoading: false,

      // ------ Contacts ---
      contacts: [],
      contactsRender: [],
      contactsRenderFields: [
        { key: "prenom", label: "Prénom", tdClass: 'ui-helper-center', sortable: true},
        { key: "nom", label: "Nom", tdClass: 'ui-helper-center', sortable: true},
        { key: "date_de_naissance", label: "Date de naissance", tdClass: 'ui-helper-center' },
        { key: "email", label: "Email", tdClass: 'ui-helper-center'},
        { key: "telephone", label: "Numéro de téléphone", tdClass: 'ui-helper-center'},
      ],

      contactsFileRenderFields: {
        'Prénom': 'prenom',
        'Nom':'nom',
        'Date de naissance': 'date_de_naissance',
        'Email': 'email',
        'Numéro de téléphone': 'telephone',
        'Nom du client associé': 'nom_du_client',
        'Informations complémentaires': 'observations'
      },


      // ---- Contact getters --
      isAllContactsFilter: true,
      isAllOwnContactsFilter: false,

      baseGetAllContactUrl: apiBaseUrl + '/all-contacts',
      getAllContactsUrl: apiBaseUrl + '/all-contacts',
      baseGetAllOwnContactsUrl: apiBaseUrl + '/all-own-contacts',
      getAllOwnContactsUrl: apiBaseUrl + '/all-own-contacts',

      searchContactFirstOrLastName: '',

    }
  },
  computed: {
    token () {
      return this.$store.state.auth.token
    },
  },
  created: function() {
    this.$dayjs.locale('fr')
    this.getAllContacts()
  },
  validations: {
    searchContactFirstOrLastName: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(250),
    },
  },
  watch: {
    contacts: function (newContacts) {
      if (newContacts.length == 0) {
        this.contactsRender = []
      }
      else {
        var final_array = []

        for (var i = 0; i < newContacts.length; i++) {
          final_array.push(
            {
              'id': newContacts[i].id,
              'sex': newContacts[i].sex,
              'client': newContacts[i].client,
              'nom_du_client': newContacts[i].client_name,
              'prenom': newContacts[i].first_name,
              'nom': newContacts[i].last_name,
              'date_de_naissance': newContacts[i].birthdate,
              'email': newContacts[i].email,
              'phone_country_code': newContacts[i].phone_country_code,
              'telephone': newContacts[i].phone_number,
              'observations': newContacts[i].observations,
            }
          )
        }
        this.contactsRender = final_array
      }
    },
  },
  methods: {

    // ---------- Getters ------------------

    getAllContacts (isReset) {
      this.isLoading = true
      if (isReset) {
        this.contacts = []
        this.getAllContactsUrl = this.baseGetAllContactUrl
      }
      apiUserConnected.getAllContacts(this.token, this.getAllContactsUrl)
      .then((result) => {
        this.contacts = this.contacts.concat(result.data.results)
        this.getAllContactsUrl = result.data.next
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.isAllContactsFilter = true
        this.isAllOwnContactsFilter = false
        this.isLoading = false
      })
    },

    getAllOwnContacts (isReset) {
      this.isLoading = true
      if (isReset) {
        this.contacts = []
        this.getAllOwnContactsUrl = this.baseGetAllOwnContactsUrl
      }
      apiUserConnected.getAllOwnContacts(this.token, this.getAllOwnContactsUrl)
      .then((result) => {
        this.contacts = this.contacts.concat(result.data.results)
        this.getAllOwnContactsUrl = result.data.next
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.isAllContactsFilter = false
        this.isAllOwnContactsFilter = true
        this.isLoading = false
      })
    },

    searchContactByFirstOrLastName () {
      this.isLoading = true
      this.contacts = []
      apiUserConnected.searchContactByFirstOrLastName(this.token, this.searchContactFirstOrLastName)
      .then((result) => {
        this.contacts = result.data
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.isAllContactsFilter = false
        this.isAllOwnContactsFilter = false
        this.isLoading = false
      })
    },


    filterAllContacts () {
      this.getAllContactsUrl = this.baseGetAllContactUrl
      this.contacts = []
      this.getAllContacts(true)
    },

    filterAllOwnContacts () {
      this.getAllOwnContactsUrl = this.baseGetAllOwnContactsUrl
      this.contacts = []
      this.getAllOwnContacts(true)
    },

    goToContactPage(item) {
      this.$router.push('/contact-details/' + item.id)
    }


  }
}
</script>

<style>
.minWidthTd {
   white-space: nowrap;
}
.ui-helper-center {
    text-align: center;
}
.table thead th {
  vertical-align: middle !important;
}
.cursor-table tr {
  cursor: pointer;
}

</style>
